<template>
    <div>
        <v-skeleton-loader v-if="loading || fetchingNodePools" elevation="6" type="article@2"></v-skeleton-loader>
        <v-card v-else>
            <v-card-title class="flex-nowrap align-start">
                <router-link
                    :to="{
                        name: 'snapshot-overview',
                        params: routeParams()
                    }"
                    class="text-h6 text-decoration-none secondary--text mr-3">
                    <span>{{ item.space_name }}</span>
                </router-link>
                <v-spacer></v-spacer>
                <v-btn small elevation="0" fab class="">
                    <v-icon color="secondary">{{ getAppSpaceType(item) }}</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-subtitle>
                {{ item.instance_name }}
            </v-card-subtitle>

            <v-container fluid>
                <v-row>
                    <v-col xs="4">
                        <v-card
                            flat
                            color="grey lighten-3"
                            :to="{
                                name: 'snapshot-files',
                                params: routeParams('file')
                            }">
                            <v-card-title>
                                <v-icon>folder</v-icon>
                            </v-card-title>
                            <v-card-text class="header-font">Files</v-card-text>
                        </v-card>
                    </v-col>
                    <v-col xs="4">
                        <v-card
                            flat
                            color="grey lighten-3"
                            :to="{
                                name: 'snapshot-tables',
                                params: routeParams(),
                                query: $route.query
                            }">
                            <v-card-title>
                                <v-icon>mdi-table-large</v-icon>
                            </v-card-title>
                            <v-card-text class="header-font">Tables</v-card-text>
                        </v-card>
                    </v-col>
                    <v-col xs="4">
                        <v-card
                            flat
                            color="grey lighten-3"
                            :to="{
                                name: 'snapshot-applications',
                                params: routeParams(),
                                query: $route.query
                            }">
                            <v-card-title>
                                <v-icon>desktop_windows</v-icon>
                            </v-card-title>
                            <v-card-text class="header-font">Apps</v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="align-center">
                    <v-col cols="12">
                        <p>Quick Launch:</p>
                        <v-card flat color="grey lighten-3" class="shepherd-quicklaunch">
                            <v-list-item
                                two-line
                                :to="{
                                    name: 'app-open',
                                    params: {
                                        oid: item.org_id,
                                        sid: item.space_id,
                                        iid: item.instance_id,
                                        snid: item.snid,
                                        aid: item.aid
                                    }
                                }">
                                <v-list-item-avatar>
                                    <img :src="appIcon(item)" />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="mr-1 text-truncate secondary--text header-font">
                                        {{ item.long_id }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span class="mr-3">{{ item.description }}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn
                                        v-if="
                                            !runningDeployments.some(deployment => deployment.aid.toString() === item.aid.toString()) &&
                                            !startingDeployments.some(deployment => deployment.aid.toString() === item.aid.toString())
                                        "
                                        fab
                                        dark
                                        x-small
                                        color="primary">
                                        <v-icon small>power_settings_new</v-icon>
                                    </v-btn>
                                    <stop-application
                                        v-if="startingDeployments.some(deployment => deployment.aid.toString() === item.aid.toString())"
                                        :app="deployedApp(item)"
                                        :appName="item.long_id"
                                        :isShared="deployedApp(item).shared === 'true'">
                                        <v-btn fab x-small dark color="deep-orange lighten-2">
                                            <v-progress-circular size="18" color="white" indeterminate />
                                        </v-btn>
                                    </stop-application>
                                    <stop-application
                                        v-if="runningDeployments.some(deployment => deployment.aid.toString() === item.aid.toString())"
                                        :app="deployedApp(item)"
                                        :appName="item.long_id"
                                        :isShared="deployedApp(item).shared === 'true'">
                                        <v-btn fab x-small dark color="success">
                                            <v-icon small>power_settings_new</v-icon>
                                        </v-btn>
                                    </stop-application>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider class="mx-4"></v-divider>
                            <v-card-actions>
                                <v-container fluid>
                                    <v-row>
                                        <v-col class="d-flex align-center py-1">
                                            <span>Size:</span>
                                            <app-scale-info-menu :app="item">
                                                <v-chip label class="ml-2 pl-4">
                                                    <template v-if="item.active_resource === 'ncu'">
                                                        <v-icon small left color="primary">mdi-card-bulleted-outline</v-icon>
                                                        {{ `${item.ncu} CU` }}
                                                    </template>
                                                    <template v-else>
                                                        <v-icon small left color="purple" v-if="getNodepoolFamily(currentNodePool(item.node_pool)) === 'cpu'">
                                                            mdi-memory
                                                        </v-icon>
                                                        <v-icon
                                                            small
                                                            left
                                                            color="light-green"
                                                            v-if="getNodepoolFamily(currentNodePool(item.node_pool)) === 'gpu'">
                                                            mdi-memory mdi-rotate-45
                                                        </v-icon>
                                                        <v-icon small left color="orange" v-if="getNodepoolFamily(currentNodePool(item.node_pool)) === 'ssd'">
                                                            mdi-nas
                                                        </v-icon>
                                                        {{ `${getNodepoolPrimaryParameter(currentNodePool(item.node_pool))}` }}
                                                    </template>
                                                </v-chip>
                                            </app-scale-info-menu>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="auto" class="d-flex align-center py-1">
                                            <span class="mr-2">Credit/hour:</span>
                                            <span v-if="item.active_resource === 'ncu'">-</span>
                                            <span v-else>{{ currentNodePool(item.node_pool).credits_per_hour.toFixed(2) }}</span>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'
import { scaling } from '@/mixins/scaling'

const StopApplication = () => import('@/modules/application/components/TheApplicationStopDialog')
const AppScaleInfoMenu = () => import('@/components/AppScaleInfoMenu.vue')

export default {
    name: 'DashboardQuickAccessCard',
    components: { StopApplication, AppScaleInfoMenu },
    props: ['item', 'loading'],
    mixins: [enumsData, appTypeAndImageLink, scaling],
    methods: {
        getAppSpaceType(app) {
            if (this.educationSpaces.find(space => space.sid === app.space_id)) {
                return 'school'
            }
            if (this.researchSpaces.find(space => space.sid === app.space_id)) {
                return 'mdi-beaker'
            }
            if (this.datasetSpaces.find(space => space.sid === app.space_id)) {
                return 'mdi-database'
            }
        },
        routeParams(type) {
            const param = {
                oid: this.item.org_id,
                sid: this.item.space_id,
                iid: this.item.instance_id,
                snid: this.item.snid
            }
            if (type === 'file') param.fileArea = this.folderTypesLabels.WORKSPACE_FILES
            return param
        },
        deployedApp(recentApp) {
            const appDeployment = this.deploymentStatus.find(deployment => {
                return deployment.aid.toString().toString() === recentApp.aid.toString()
            })
            const deployedAppData = Object.assign({}, appDeployment)
            if (appDeployment) {
                if (appDeployment.replicas === appDeployment.available_replicas) {
                    deployedAppData.status = 'Running'
                } else if (appDeployment.replicas > appDeployment.available_replicas) {
                    deployedAppData.status = 'Starting'
                } else {
                    deployedAppData.status = 'Stopping'
                }
            }

            return deployedAppData
        }
    },
    computed: {
        ...mapState('orgStore', ['researchSpaces', 'educationSpaces', 'datasetSpaces']),
        ...mapState('appStore', ['deploymentStatus']),
        ...mapGetters('appStore', ['runningDeployments', 'startingDeployments'])
    }
}
</script>
